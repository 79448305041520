import "jquery";
import "popper.js";
import "bootstrap";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";
import "./style.scss";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({ once: true });

if (process.env.NODE_ENV !== "production") {
  console.log("Looks like we are in development mode!");
}
